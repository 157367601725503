import React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/Layout/Layout"
import LinkButton from "../components/LinkButton/LinkButton"

import "./job-post.scss"
import { usePageContext } from "../pageContext"

//TO DO: description for all/single job post?

const renderOptions = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      if (node.nodeType === "list-item") {
        return <li>{node.content[0].content[0].value}</li>
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) =>
      children !== "" ? <p>{children}</p> : "",
  },
}

export default function JobPost({ data, location }) {
  const job = data?.contentfulJobPost
  const { slug } = usePageContext()

  return (
    <Layout title={job?.title}>
      <main className="job-post-container">
        <div className="job-header-container">
          <h1>{job?.title}</h1>
          <ul className="job-subtitle">
            <li>{job?.department?.departmentTitle}</li>
            <li>{job?.type?.name}</li>
          </ul>
        </div>
        <p className="description">{job?.description?.description}</p>
        <hr className="divider" />
        <h2>In this role, you will:</h2>
        {renderRichText(job?.requirements, renderOptions)}
        <h2>What You Need To Be Successful:</h2>
        {renderRichText(job?.niceToHaves, renderOptions)}
        <h2>Benefits:</h2>
        {renderRichText(job?.benefits, renderOptions)}
        <LinkButton
          to={`/careers/${slug}/apply/`}
          variant="primary"
          state={{
            ...location?.state,
          }}
        >
          Apply for this Role
        </LinkButton>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $locale: String) {
    contentfulJobPost(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      requirements {
        raw
      }
      niceToHaves {
        raw
      }
      benefits {
        raw
      }
      department {
        ... on ContentfulJobDepartment {
          contentful_id
          __typename
          departmentTitle
        }
      }
      type {
        ... on ContentfulJobType {
          contentful_id
          __typename
          name
        }
      }
    }
  }
`
